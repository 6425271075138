module.exports = {
    validatePhonenumber: function (value) {
        let re_phonenumber = /^[0-9]{7}$/;
        return re_phonenumber.test(value);
    },
    validatePin: function (value) {
        let re_pin = /^[0-9]{5}$/;
        return re_pin.test(value);
    },
    validateEmail: function (value) {
        let re_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re_email.test(value);
    },
    validatePassword: function (value) {
        let re_number = /\d/;
        let re_upperCase = /[A-Z]/;
        let re_symbol = /[^\w\d]/;
        return (value.length >= 8 && re_number.test(value) && re_upperCase.test(value) && re_symbol.test(value));
    }
};