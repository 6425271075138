import React from 'react';
import locale from './lang/locale.json';
import Header from "./components/Header";
import Simavist from "./components/Simavist";



function App() {
  return (
        <div className='simavistContainer'>
            <Header locale={locale}/>
            <Simavist locale={locale}/>
        </div>
  );
}

export default App;
