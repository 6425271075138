import React, {Component} from 'react';
import Icon from "./Icon/Icon";


class Header extends Component {
    render() {
        return (
            <>
                <Icon icon="logo"/>
                <h1>{this.props.locale.common.title}</h1>
            </>
        );
    }
}

export default Header;