import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import {Button, Form, Input} from 'antd';
var utils = require('../utils/validationUtils');


class Simavist extends React.Component {
    static propTypes = {
        locale: PropTypes.objectOf(PropTypes.string).isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            step: "start",
            texts: this.props.locale.phonenumber_page,
            email: "",
            password: "",
            passwordMatch: false,
            sessionId: Math.floor(10000000000 + Math.random() * 90000000000),
            errorMessage: "",
            emailMessage: false,
            baseUrl: "/api" /*"http://localhost:9000/broadsoftAPI"*/
        };
    }

    goToStep2 = values => {
        fetch(this.state.baseUrl + "/sendPin?sessionId=" + this.state.sessionId)
            .then(response => response.json())
            .then(data => {
                if (data.success) this.setState({step: "sms", texts: this.props.locale.pin_page});
                else this.setState({step: "error", errorMessage: this.props.locale.error.send});
            })
            .catch((error) => this.setState({step: "error", errorMessage: this.props.locale.error.send}));
    };
    goToStep3 = values => {
        this.setState({step: "password", texts: this.props.locale.password_page});
    };
    goToLastStep = values => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ password: values.password,
                email: values.email})
        };
        fetch(this.state.baseUrl + "/updatePasswordAndEmailAndLogout?sessionId=" + this.state.sessionId, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if(data.message)
                        this.setState({step: "success", emailMessage: true});
                    else
                        this.setState({step: "success"});
                }
                else this.setState({step: "error", errorMessage: this.props.locale.error.update});
            })
            .catch((error) => this.setState({step: "error", errorMessage: this.props.locale.error.update}));
    };
    validatePhonenumber = async (rule, value) => {
        if (utils.validatePhonenumber(value)) {
            await fetch(this.state.baseUrl + "/loginAndGetUser?simavistNumber=" + value + "&sessionId=" + this.state.sessionId)
                .then(response => response.json())
                .then(data => {
                    if (data.email || data.mobilePhoneNumber) {
                        this.setState({email: data.email});
                        return Promise.resolve();
                    } else throw "NoEmailOrPhonenumber";
                })
                .catch((error) => {
                    if (error === "NoEmailOrPhonenumber") return Promise.reject(new Error(this.state.texts.input.validation.emailOrPhonenumberNotFound));
                    else return Promise.reject(new Error(this.state.texts.input.validation.userNotFound));
                })
        } else return Promise.reject(new Error(this.state.texts.input.validation.invalid));
    };
    validatePin = async (rule, value) => {
        if (utils.validatePin(value)) {
            await fetch(this.state.baseUrl + "/verifyPin?pin=" + value + "&sessionId=" + this.state.sessionId)
                .then(response => response.json())
                .then(data => {
                    if (data.success) return Promise.resolve();
                    else throw new Error();
                })
                .catch((error) => {
                    return Promise.reject(new Error(this.state.texts.input.validation.invalid));
                });
        } else return Promise.reject(new Error(this.state.texts.input.validation.invalid));
    };
    validateEmail = (rule, value) => {
        if (utils.validateEmail(value)) return Promise.resolve();
        else return Promise.reject(new Error(this.state.texts.input.validation.invalidEmail));
    };
    validatePassword = (rule, value) => {
        if (utils.validatePassword(value)) {
            this.setState({password: value});
            return Promise.resolve();
        } else return Promise.reject(new Error(this.state.texts.input.validation.invalid));
    };
    validatePassword2 = (rule, value) => {
        if (utils.validatePassword(value) && value === this.state.password) {
            this.setState({password: value});
            this.setState({passwordMatch: true});
            return Promise.resolve();
        } else {
            this.setState({passwordMatch: false});
            return Promise.reject(new Error(this.state.texts.input.validation.invalid));
        }
    };

    render() {
        switch (this.state.step) {
            case "start":
                return (
                    <Form onFinish={this.goToStep2}>
                        <Form.Item
                            label={this.state.texts.input.title}
                            name={this.state.texts.input.name}
                            rules={[{validator: this.validatePhonenumber}]}
                            validateTrigger="onSubmit">
                            <Input placeholder={this.state.texts.input.placeholder}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {this.state.texts.button}
                            </Button>
                        </Form.Item>
                    </Form>
                );
            case "sms":
                return (
                    <Form onFinish={this.goToStep3}>
                        <Form.Item
                            label={this.state.texts.input.title}
                            name={this.state.texts.input.name}
                            rules={[{validator: this.validatePin}]}
                            validateTrigger="onSubmit">
                            <Input placeholder={this.state.texts.input.placeholder}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {this.state.texts.button}
                            </Button>
                        </Form.Item>
                    </Form>
                );
            case "password":
                return (
                    <Form onFinish={this.goToLastStep}>
                        <p>{this.props.locale.password_page.information1}</p>
                        <p>{this.props.locale.password_page.information2}<br/>
                            {this.props.locale.password_page.information3}</p>
                        <Form.Item
                            label={this.state.texts.input.title}
                            name={this.state.texts.input.name}
                            rules={[{validator: this.validatePassword}]}
                            validateTrigger="onSubmit">
                            <Input.Password placeholder={this.state.texts.input.placeholder}/>
                        </Form.Item>
                        <Form.Item
                            label={this.state.texts.input2.title}
                            name={this.state.texts.input2.name}
                            rules={[{validator: this.validatePassword2}]}
                            validateTrigger="onSubmit">
                            <Input.Password placeholder={this.state.texts.input2.placeholder}/>
                        </Form.Item>
                        {this.state.passwordMatch &&
                        <span className="successText">Lykilorð passa</span>
                        }
                        <Form.Item
                            label={this.state.email ? (this.state.texts.input3.title + ':') : this.state.texts.input3.title2}
                            name={this.state.texts.input3.name}
                            rules={[{validator: this.validateEmail}]}
                            initialValue={this.state.email}
                            validateTrigger="onSubmit">
                            <Input placeholder={this.state.texts.input3.placeholder}/>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                {this.state.texts.button}
                            </Button>
                        </Form.Item>
                    </Form>
                );
            case "success":
                return (
                    <>
                        <h2>{this.props.locale.success.title}</h2>
                        <p>{this.props.locale.success.text3}</p>
                        {this.state.emailMessage ?
                            <p>{this.props.locale.error.emailDuplicate}</p>
                            :
                            ''
                        }
                    </>
                );
            default:
                return (
                    <>
                        <h2 className="errorText">{this.props.locale.error.title}</h2>
                        <p>{this.state.errorMessage}</p>
                        <p>{this.props.locale.error.text}</p>
                    </>
                );
        }
    }
}

export default Simavist;
